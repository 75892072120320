import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "status", "input", "submit"];
  static values = {
    labelUnsaved: String,
  };

  connect() {
    this.labels = {
      unsaved: this.labelUnsavedValue || "Not yet saved",
    };
  }

  enableSubmit() {
    if (this.hasSubmitTarget) {
      this.submitTarget.disabled = false;
    }
  }

  disableSubmit() {
    if (this.hasSubmitTarget) {
      this.submitTarget.disabled = true;
    }
  }

  setStatus(message) {
    this.statusTarget.textContent = message;

    this.timeout = setTimeout(() => {
      this.statusTarget.textContent = "";
    }, 2000);
  }
}
