import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item"];

  connect() {
    if (!this.hasItemTarget) {
      return;
    }

    this.min = this.element.dataset.minItems || 1;
    this.max = this.element.dataset.maxItems || 9999;

    this.updateButtonStates();
  }

  removeElement(evt) {
    const button = evt.target;
    const item = button.closest('[data-duplicatable-target="item"]');

    if (!item) {
      return;
    }

    item.remove();
    this.updateButtonStates();
  }

  addElement(evt) {
    const button = evt.target;
    const item = button.closest('[data-duplicatable-target="item"]');

    if (!item) {
      return;
    }

    const newItem = item.cloneNode(true);
    // Reset any inputs
    newItem
      .querySelectorAll("select, input, textarea")
      .forEach((input) => (input.value = ""));
    item.after(newItem);
    this.updateButtonStates();
  }

  updateButtonStates() {
    const itemCount = this.itemTargets.length;
    this.itemTargets.forEach((item) => {
      let remove = item.querySelector(
        '[data-action="click->duplicatable#removeElement"]'
      );
      let add = item.querySelector(
        '[data-action="click->duplicatable#addElement"]'
      );
      if (remove) {
        remove.disabled = itemCount <= this.min;
      }
      if (add) {
        add.disabled = itemCount >= this.max;
      }
    });
  }
}
