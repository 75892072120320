import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["trigger"];

  connect() {
    this.triggerTargets.forEach((target) => {
      this.toggle({ currentTarget: target });
    });
  }

  disconnect() {}

  toggle(evt) {
    const trigger = evt.currentTarget;
    if (
      trigger.matches("input") &&
      typeof trigger.checked === "boolean" &&
      !trigger.checked
    ) {
      return;
    }
    this._hide(trigger);
    this._show(trigger);
  }

  _show(trigger) {
    const selector = this._getSelectorFrom(trigger, "show");
    if (!selector) {
      return;
    }
    const elements = this.element.querySelectorAll(selector);
    elements.forEach((target) => (target.hidden = false));
  }

  _hide(trigger) {
    const selector = this._getSelectorFrom(trigger, "hide");
    if (!selector) {
      return;
    }
    const elements = this.element.querySelectorAll(selector);
    elements.forEach((target) => (target.hidden = true));
  }

  _getSelectorFrom(trigger, method) {
    const el = trigger.matches("select")
      ? trigger.options[trigger.selectedIndex]
      : trigger;

    switch (method) {
      case "hide":
        return el.dataset.revealHideElements;
      case "show":
        return el.dataset.revealShowElements;
      default:
        return undefined;
    }
  }
}
