import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["trigger", "toggleable"];

  initialize() {
    if (!this.hasTriggerTarget) {
      return;
    }

    this.HANDOFF_CLASS = "header-handed-off";
    this.SHOW_CLASS = "visible";

    this.initObserver();
  }

  initObserver() {
    const observer = new IntersectionObserver(
      ([e]) => {
        e.intersectionRatio < 0.5
          ? this.showToggleables()
          : this.hideToggleables();
      },
      { threshold: [0, 0.25, 0.5, 0.75, 1] }
    );

    observer.observe(this.triggerTarget);
  }

  showToggleables() {
    this.toggleableTargets.forEach((el) => {
      el.classList.add(this.SHOW_CLASS);
      el.removeAttribute("tabindex");
    });
    this.triggerTarget.classList.add(this.HANDOFF_CLASS);
  }

  hideToggleables() {
    this.toggleableTargets.forEach((el) => {
      el.classList.remove(this.SHOW_CLASS);
      el.setAttribute("tabindex", -1);
    });
    this.triggerTarget.classList.remove(this.HANDOFF_CLASS);
  }
}
