/* eslint no-console:0 */

// Rails functionality
import { Turbo } from "@hotwired/turbo-rails";
import ahoy from "ahoy.js"

window.Turbo = Turbo;

// Web Components
import "./components";

// Stimulus controllers
import "./controllers";

// Misc
import "./src/sprite-fetcher";
import "./src/srcset-defibrillator";
import "./src/range-slider-fill";

document.addEventListener("turbo:load", () => {
  document.querySelector("html").classList.add("js");
});
