import { Controller } from "@hotwired/stimulus";
import DateHelper from "../utils/date-helper";

export default class extends Controller {
  static targets = ["timestamp"];

  connect() {
    // Do initial setup
    this.updateTimestamps();
    // Keep it going, refresh every second.
    this.pulse = window.setInterval(this.updateTimestamps.bind(this), 1000);
  }

  disconnect() {
    if (this.pulse) {
      window.clearTimeout(this.pulse);
    }
  }

  updateTimestamps() {
    this.timestampTargets.forEach((timeElement) => {
      const timestamp = new Date(timeElement.getAttribute("datetime"));
      let timestampText;
      // check that the date is valid.
      if (typeof timestamp.getMonth !== "function") {
        return;
      }
      if (timeElement.dataset.fuzzytimeTemplate) {
        // place the timestamp into the context of the timestamp template
        timestampText = timeElement.dataset.fuzzytimeTemplate.replace(
          /\[TIME\]/,
          DateHelper.timeAgoInWords(timestamp)
        );
      } else {
        timestampText = DateHelper.timeAgoInWords(timestamp);
        // Capitalize the first letter
        if (timeElement.dataset.capitalize) {
          timestampText =
            timestampText[0].toUpperCase() + timestampText.slice(1);
        }
      }
      timeElement.innerText = timestampText;
    });
  }
}
