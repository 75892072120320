import { Controller } from "@hotwired/stimulus";
import BodyScroll from "../utils/body-scroll";

export default class extends Controller {
  static targets = ["trigger"];

  connect() {
    if (!this.hasTriggerTarget) {
      return;
    }
    this.trigger = this.triggerTarget;
    this.menu = document.getElementById(
      this.trigger.getAttribute("aria-controls")
    );

    if (!this.menu) {
      return;
    }
    this.focusableElements.forEach((el) => (el.tabIndex = -1));
  }

  toggle(evt) {
    if (this.isOpen) {
      this.close(evt);
    } else {
      this.open(evt);
    }
    this.triggerTarget.blur();
  }

  open(evt) {
    BodyScroll.fix();
    this.menu.classList.add("open");
    this.trigger.setAttribute("aria-expanded", true);
    this.focusableElements.forEach((el) => el.removeAttribute("tabindex"));
  }

  close(evt) {
    BodyScroll.release();
    this.menu.classList.remove("open");
    this.trigger.setAttribute("aria-expanded", false);
    this.focusableElements.forEach((el) => (el.tabIndex = -1));
  }

  get isOpen() {
    return this.trigger.getAttribute("aria-expanded") === "true";
  }

  get focusableElements() {
    return this.menu.querySelectorAll("a, button");
  }
}
