import Dropzone from "dropzone";
import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";
import {
  getMetaValue,
  toArray,
  removeElement,
  insertAfter,
} from "../utils/helpers";

Dropzone.autoDiscover = false;

export default class extends Controller {
  static targets = ["input"];

  connect() {
    if (!this.hasInputTarget) return;

    this.dropZone = createDropZone(this);
    this.submitButton = this.form.querySelector('button[type="submit"]');
    try {
      if (this.autosubmit) {
        this.submitButton.disabled = true;
        this.submitButton.style.display = "none";
      }
    } catch (e) {}
    this.hideFileInput();
    this.bindEvents();
  }

  disconnect() {
    this.dropZone.destroy();
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
    if (this.inputTarget.required) {
      this.fileRequired = true;
      this.inputTarget.required = false;
    }
  }

  bindEvents() {
    this.dropZone.on("addedfile", (file) => {
      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start();
      }, 500);
    });

    this.dropZone.on("removedfile", (file) => {
      file.controller && removeElement(file.controller.hiddenInput);
      if (!this.dropZone.files.length && this.submitButton) {
        this.submitButton.disabled = true;
      }
    });

    this.dropZone.on("success", (file) => {
      file.previewElement.style.setProperty(
        "--thumbnail-aspect-ratio",
        `${parseInt(file.width)} / ${parseInt(file.height)}`
      );
      if (this.autosubmit) {
        if (this.remote) {
          this.form.dataset.remote = "true";
        } else {
          this.form.removeAttribute("data-remote");
        }
        this.form.dispatchEvent(
          new Event("submit", {
            bubbles: true,
            cancelable: true,
          })
        );
        this.dropZone.removeAllFiles();
      } else if (this.submitButton) {
        this.submitButton.disabled = false;
      }
    });

    this.dropZone.on("canceled", (file) => {
      file.controller && file.controller.xhr.abort();
    });
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return (
      this.inputTarget.dataset.directUploadUrl || this.inputTarget.form.action
    );
  }

  get form() {
    return this.inputTarget.form;
  }

  get token() {
    return this.inputTarget.dataset.directUploadToken;
  }

  get attachmentName() {
    return this.inputTarget.dataset.directUploadAttachmentName;
  }

  get maxFiles() {
    return this.element.dataset.maxFiles || 1;
  }

  get maxFileSize() {
    return this.element.dataset.maxFileSize || 256;
  }

  get acceptedFiles() {
    return this.inputTarget.getAttribute("accept");
  }

  get addRemoveLinks() {
    return this.element.dataset.addRemoveLinks || true;
  }

  get thumbnailWidth() {
    return this.element.dataset.thumbnailWidth || null;
  }

  get thumbnailHeight() {
    return this.element.dataset.thumbnailHeight || null;
  }

  get autosubmit() {
    return this.element.dataset.autosubmit === "true";
  }

  get remote() {
    return this.element.dataset.remote === "true";
  }

  get previewsContainer() {
    return (
      this.element.querySelector(this.element.dataset.previewsContainer) ||
      this.element
    );
  }

  get clickableElements() {
    return this.element.dataset.clickableElements;
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(
      file,
      source.url,
      source.token,
      source.attachmentName
    );
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    const formId = this.source.inputTarget.getAttribute("form");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    if (formId) {
      input.setAttribute("form", formId);
    }
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", (event) =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    const progressBar = this.file.previewTemplate.querySelector(".dz-upload");

    if (progressBar) {
      progressBar.style.width = `${progress}%`;
    }
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, token, attachmentName) {
  return new DirectUpload(file, url, token, attachmentName);
}

function createDropZone(controller) {
  const options = {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    thumbnailWidth: controller.thumbnailWidth,
    thumbnailHeight: controller.thumbnailHeight,
    previewsContainer: controller.previewsContainer,
    dictRemoveFile: "Remove",
    autoQueue: false,
  };
  if (controller.clickableElements) {
    options.clickable = controller.clickableElements;
  }
  return new Dropzone(controller.element, options);
}
