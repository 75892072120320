import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["plan", "control"];

  connect() {
    this.recalculate();
  }

  recalculate(evt) {
    if (typeof evt?.preventDefault === "function") {
      evt.preventDefault();
    }
    if (this.controlTarget.value) {
      const currentVal = parseInt(this.controlTarget.value.replace(/\D/g, ""));
      this.controlTarget.setAttribute("step", currentVal > 99 ? 50 : 1);
      const val = Math.min(currentVal, parseInt(this.controlTarget.max));
      this.controlTarget.value = val;
    }
    this.planTargets.forEach((plan) => this.updatePrice(plan));
  }

  updatePrice(plan) {
    const maxMemberCount = plan.dataset.maxMemberCount
      ? parseInt(plan.dataset.maxMemberCount, 10)
      : null;
    const basePrice = parseInt(plan.dataset.basePrice, 10);
    let selectedMemberCount =
      parseInt(this.controlTarget.value, 10) ||
      parseInt(this.controlTarget.min, 10);
    let total;
    if (maxMemberCount) {
      if (maxMemberCount < selectedMemberCount) {
        selectedMemberCount = maxMemberCount;
        this._disablePlan(plan);
      } else {
        this._enablePlan(plan);
      }
    }
    if (selectedMemberCount <= baseMemberCount) {
      total = basePrice;
    } else {
      const unitCount = Math.ceil(
        (selectedMemberCount - baseMemberCount) / unitMemberCount
      );
      total = basePrice + unitCount * unitPrice;
    }

    const totalDollars = total / 100;
    const price = this._getFormattedPriceFor(totalDollars, "font-size:default");
    plan.querySelector(".price").innerHTML = price;

    const annual = plan.querySelector(".annual-total");
    if (annual) {
      const totalAnnualDollars = totalDollars * 12;
      annual.innerHTML = this._getFormattedPriceFor(totalAnnualDollars);
    }
  }

  _getFormattedPriceFor(totalDollars, superscriptClass = "") {
    return totalDollars
      .toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: parseInt(totalDollars) === totalDollars ? 0 : 2,
      })
      .replace(/\.(\d{2,})/, `<sup class="${superscriptClass}">.$1</sup>`);
  }

  _disablePlan(plan) {
    plan.classList.add("disabled");
    plan.querySelectorAll("form, button, input, a").forEach((el) => {
      if (el.dataset.disallowManipulation) {
        return;
      }
      el.disabled = true;
      el.tabIndex = -1;
    });
    const limitations = plan.parentElement.querySelector(".plan-limitations");
    if (limitations) limitations.hidden = false;
  }

  _enablePlan(plan) {
    plan.classList.remove("disabled");
    plan.querySelectorAll("form, button, input, a").forEach((el) => {
      if (el.dataset.disallowManipulation) {
        return;
      }
      el.disabled = false;
      el.removeAttribute("tabindex");
    });
    const limitations = plan.parentElement.querySelector(".plan-limitations");
    if (limitations) limitations.hidden = true;
  }
}
