import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab", "panel"];

  connect() {
    this.activeTabClasses = (this.data.get("activeTab") || "active").split(" ");
    this.inactiveTabClasses = (
      this.data.get("inactiveTab") || "inactive"
    ).split(" ");

    if (this.tabTarget.matches('[type="radio"]')) {
      this.index = this.tabTargets
        .filter((tab) => !tab.closest("[hidden]"))
        .findIndex((el) => el.checked);
      if (this.index === -1) {
        this.index = null;
      }
    } else if (this.anchor) {
      this.index = this.panelTargets.findIndex(
        (panel) => panel.id === this.anchor
      );
    }

    this.showTab();
  }

  change(event) {
    event.preventDefault();
    const tab = event.currentTarget;

    // If target specifies an index, use that
    if (tab.dataset.index) {
      this.index = tab.dataset.index;

      // If target specifies an id, use that
    } else if (tab.dataset.id) {
      this.index = this.tabTargets.findIndex(
        (tabTarget) => tabTarget.id == tab.dataset.id
      );

      // Otherwise, use the index of the current target
    } else {
      this.index = this.tabTargets.indexOf(tab);
    }

    window.dispatchEvent(new CustomEvent("tab:change"));
  }

  showTab() {
    this.tabTargets.forEach((tab, index) => {
      const panels = tab.dataset.tabPanel
        ? this.panelTargets.filter((panel) =>
            panel.matches(tab.dataset.tabPanel)
          )
        : [this.panelTargets[index]];

      if (index === this.index) {
        panels.forEach((panel) => this.show(panel));
        tab.classList.remove(...this.inactiveTabClasses);
        tab.classList.add(...this.activeTabClasses);
      } else {
        panels.forEach((panel) => this.hide(panel));
        tab.classList.remove(...this.activeTabClasses);
        tab.classList.add(...this.inactiveTabClasses);
      }
    });
  }

  show(panel) {
    panel.hidden = false;
    panel.removeAttribute("tabindex");
  }

  hide(panel) {
    panel.hidden = true;
    panel.tabIndex = -1;
  }

  get index() {
    return parseInt(this.data.get("index") || 0);
  }

  set index(value) {
    this.data.set("index", value >= 0 ? value : 0);
    this.showTab();
  }

  get anchor() {
    return document.URL.split("#").length > 1
      ? document.URL.split("#")[1]
      : null;
  }
}
