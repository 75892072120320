import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

export default class extends Controller {
  static values = {
    showSearch: { type: Boolean, default: true },
  };

  connect() {
    this.select = new SlimSelect({
      select: this.element,
      settings: {
        showSearch: this.showSearchValue,
      },
    });
  }

  disconnect() {
    this.select.destroy();
  }
}
