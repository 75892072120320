import { Controller } from "@hotwired/stimulus";
import { Editor, isTextSelection } from "@tiptap/core";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import FocusTrap from "../../utils/focus-trap";
import Mention from "@tiptap/extension-mention";
import Typography from "@tiptap/extension-typography";
import TextAlign from "@tiptap/extension-text-align";
import BubbleMenu from "@tiptap/extension-bubble-menu";
import ContentImage from "./editor/extension-content-image";
import suggestion from "./editor/suggestion";

const actions = [
  "bold",
  "italic",
  "strike",
  "link",
  "orderedList",
  "bulletList",
  "blockquote",
  "code",
  "codeBlock",
  "horizontalRule",
  "heading",
  "textAlign",
];

export default class extends Controller {
  static targets = [
    "content",
    "input",
    "inputWrapper",
    "bubbleMenu",
    "imageBubbleMenu",
    "contentImageIds",
  ].concat(actions);

  connect() {
    if (!this.hasContentTarget) {
      return;
    }

    if (this.hasInputWrapperTarget) {
      this.inputWrapperTarget.style.display = "none";
    }

    this.configuration = this.element.dataset.editorConfiguration || "full";

    this.editor = new Editor({
      element: this.contentTarget,
      extensions: this.extensions,
      editorProps: {
        attributes: {
          class: "prose prose-editor",
        },
        handleClickOn: (view, pos, node) => {},
        handleDrop: this._handleDropEvent.bind(this),
      },
      content: this.inputTarget?.value || "",
      onUpdate: this.handleUpdate.bind(this),
      onTransaction: this.updateToolbarState.bind(this),
    });
  }

  disconnect() {
    this.editor.destroy();
  }

  handleUpdate() {
    this.inputTarget.value = this.editor.isEmpty ? "" : this.editor.getHTML();
    this.inputTarget.dispatchEvent(new Event("input"));
  }

  updateToolbarState() {
    actions.forEach((state) => this._updateButtons(state));
  }

  toggleBold(evt) {
    this._toggleButton(evt.target, "bold");
  }

  toggleItalic(evt) {
    this._toggleButton(evt.target, "italic");
  }

  toggleStrike(evt) {
    this._toggleButton(evt.target, "strike");
  }

  toggleOrderedList(evt) {
    this._toggleButton(evt.target, "orderedList");
  }

  toggleBulletList(evt) {
    this._toggleButton(evt.target, "bulletList");
  }

  toggleQuote(evt) {
    this._toggleButton(evt.target, "blockquote");
  }

  toggleCode(evt) {
    this._toggleButton(evt.target, "code");
  }

  toggleCodeBlock(evt) {
    this._toggleButton(evt.target, "codeBlock");
  }

  setHorizontalRule(evt) {
    this.editor.chain().focus().setHorizontalRule().run();
  }

  setTextAlign(evt) {
    this.editor
      .chain()
      .focus()
      .setTextAlign(evt.currentTarget.dataset.textAlignment)
      .run();
  }

  toggleHeading(evt) {
    this.editor
      .chain()
      .focus()
      .toggleHeading({ level: Number(evt.currentTarget.dataset.headingLevel) })
      .run();
  }

  editLink(evt) {
    const link = this.editor.getAttributes("link");
    const text = link.innerText || this.selectedText;
    const url = link.href;
    this._openLinkEditor(text, url);
  }

  deleteNode(evt) {
    this.editor.chain().focus().deleteSelection().run();
  }

  _updateButtons(state) {
    if (this[`has${this._capitalize(state)}Target`]) {
      this[`${state}Targets`].forEach((target) => {
        if (state === "textAlign") {
          target.classList[
            this.editor.isActive({ textAlign: target.dataset.textAlignment })
              ? "add"
              : "remove"
          ]("active");
        } else {
          const config = {};
          if (state === "heading") {
            config.level = Number(target.dataset.headingLevel);
          }
          target.classList[
            this.editor.isActive(state, config) ? "add" : "remove"
          ]("active");
        }
      });
    }
  }

  _toggleButton(button, state, command = "toggle") {
    this.editor.chain().focus()[`${command}${this._capitalize(state)}`]().run();
  }

  _capitalize(s) {
    return s && s[0].toUpperCase() + s.slice(1);
  }

  get selectedText() {
    const { view, state } = this.editor;
    const { from, to } = view.state.selection;
    return state.doc.textBetween(from, to, " ");
  }

  _openLinkEditor(text = "", url = "") {
    const id = "link-editor-modal-" + String(Math.random()).slice(2, -1);
    const header = "Edit Link";
    const textLabel = "Text";
    const linkLabel = "Link";
    const confirmButtonLabel = "Save";
    const cancelButtonLabel = "Cancel";

    var content = `
      <section id="${id}" class="modal-dialog confirmation stack">
        <header class="header">
          <h4>${header}</h4>
        </header>
        <div class="content stack">

          <div class="stack-comfy">
            <div class="stack-tight">
              <label>${textLabel}</label>
              <input type="text" name="linktext" class="input-size:small" value="${text}">
            </div>

            <div class="stack-tight">
              <label>${linkLabel}</label>
              <input type="url" name="linkurl" class="input-size:small" value="${url}">
            </div>
          </div>

          <div class="actions cluster-spread">
            <button type="button" data-behavior="commit" class="btn btn-small">${confirmButtonLabel}</button>
            <button type="button" data-behavior="cancel" class="btn btn-secondary btn-outline btn-tiny">${cancelButtonLabel}</button>
          </div>
        </div>
      </section>
      <div class="modal-dialog-underlay"></div>
    `;

    document.body.insertAdjacentHTML("beforeend", content);

    const modal = document.getElementById(id);
    const underlay = modal.nextElementSibling;

    this._bindLinkEditorEvents(modal);

    this._setFocus(modal);

    this._linkEditor = {
      modal: modal,
      underlay: underlay,
      url: url,
      text: text,
    };
  }

  _bindLinkEditorEvents(modal) {
    modal.addEventListener("keyup", (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
        this._closeLinkEditor();
      }
      if (event.key === "Enter") {
        this._setLink();
      }
    });

    modal
      .querySelector('input[name="linktext"]')
      .addEventListener(
        "input",
        (evt) => (this._linkEditor.text = evt.target.value)
      );

    modal
      .querySelector('input[name="linkurl"]')
      .addEventListener(
        "input",
        (evt) => (this._linkEditor.url = evt.target.value)
      );

    modal
      .querySelector("[data-behavior='cancel']")
      .addEventListener("click", this._closeLinkEditor.bind(this));

    modal
      .querySelector("[data-behavior='commit']")
      .addEventListener("click", this._setLink.bind(this));
  }

  _setLink() {
    if (this.selectedText) {
      if (!this._linkEditor.url) {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();
      } else {
        this.editor
          .chain()
          .focus()
          .extendMarkRange("link")
          .setLink({ href: this._linkEditor.url })
          .run();
      }
    } else {
      this.editor.commands.insertContent(
        `<a href="${this._linkEditor.url}">${this._linkEditor.text}</a>`
      );
    }
    this._closeLinkEditor();
  }

  _closeLinkEditor() {
    if (!this._linkEditor) {
      return;
    }

    if (this._focusTrap) {
      this._focusTrap.release();
      this._focusTrap = null;
    }

    this._linkEditor.modal.remove();
    this._linkEditor.underlay.remove();
    this._linkEditor = null;

    this.editor.chain().focus().run();
  }

  _setFocus(element) {
    this._focusTrap = new FocusTrap(element);
    this._focusTrap.set();
    this._focusTrap.highlightFirstFocusableElement();
  }

  get extensions() {
    const extensionSet = [
      StarterKit.configure({
        horizontalRule: this.configuration === "full",
        heading: this.configuration === "full" ? { levels: [2, 3, 4] } : false,
      }),
      Placeholder.configure({
        placeholder: this.inputTarget.placeholder || "Write something...",
      }),
      Link.configure({
        openOnClick: false,
      }),
      Mention.configure({
        HTMLAttributes: {
          class: "mention",
        },
        suggestion,
      }),
      Typography,
      ContentImage,
    ];

    if (this.configurationValue === "full") {
      extensionSet.push(
        TextAlign.configure({
          types: ["heading", "paragraph"],
        })
      );
    }

    if (this.hasBubbleMenuTarget) {
      extensionSet.push(
        BubbleMenu.configure({
          element: this.bubbleMenuTarget,
          pluginKey: "textBubbleMenu",
          tippyOptions: {
            theme: "groupnews-editor",
            animation: "shift-toward-subtle",
            duration: [100, 200],
            maxWidth: "none",
            pluginKey: "textMenu",
          },
          shouldShow: ({ editor, view, state, oldState, from, to }) => {
            // Copied from https://github.com/ueberdosis/tiptap/blob/develop/packages/extension-bubble-menu/src/bubble-menu-plugin.ts with an additional check for images
            const { doc, selection } = state;
            const { empty } = selection;
            const isEmptyTextBlock =
              !doc.textBetween(from, to).length &&
              isTextSelection(state.selection);
            const isChildOfMenu = this.element.contains(document.activeElement);
            const imageHasFocus = editor.isActive("image");
            const hasEditorFocus = view.hasFocus() || isChildOfMenu;

            if (
              !hasEditorFocus ||
              imageHasFocus ||
              empty ||
              isEmptyTextBlock ||
              !this.editor.isEditable
            ) {
              return false;
            }

            return true;
          },
        })
      );
    }

    if (this.hasImageBubbleMenuTarget) {
      extensionSet.push(
        BubbleMenu.configure({
          element: this.imageBubbleMenuTarget,
          pluginKey: "imageBubbleMenu",
          tippyOptions: {
            theme: "groupnews-editor",
            animation: "shift-toward-subtle",
            duration: [100, 200],
            maxWidth: "none",
            pluginKey: "imageMenu",
          },
          shouldShow: ({ editor }) => {
            return editor.isActive("image");
          },
        })
      );
    }

    return extensionSet;
  }

  get _allowedFileTypes() {
    return ["image/jpeg", "image/png", "image/gif", "image/webp"];
  }

  _handleDropEvent(view, event, slice, moved) {
    if (
      !moved &&
      event.dataTransfer &&
      event.dataTransfer.files &&
      event.dataTransfer.files[0]
    ) {
      const files = event.dataTransfer.files;
      let filesValid = true;
      files.forEach((file) => {
        const filesize = (file.size / 1024 / 1024).toFixed(4); // in MB
        if (!this._allowedFileTypes.includes(file.type) || filesize > 10) {
          filesValid = false;
        }
      });

      if (filesValid) {
        // Create previews
        files.forEach((file) => {
          const image = document.createElement("img");
          const src = URL.createObjectURL(file);
          image.src = src;
          image.classList.add("upload-preview");
          image.onload = () => {
            const { schema } = view.state;
            const coordinates = view.posAtCoords({
              left: event.clientX,
              top: event.clientY,
            });
            const node = schema.nodes.image.create({
              src: src,
              class: `upload-preview`,
            });
            const transaction = view.state.tr.insert(coordinates.pos, node);
            return view.dispatch(transaction);
          };
        });
        this._uploadImage(files)
          .then((response) => response.json())
          .then(({ images }) => {
            images.forEach((image) =>
              this._createImageHTML(view, event, image)
            );
            this._updateContentImageIdList(images);
          })
          .catch((error) => {
            if (error) {
              window.alert(
                "There was a problem uploading your image, please try again."
              );
            }
          })
          .finally(() => {
            document
              .querySelectorAll(".upload-preview")
              .forEach((image) => image.remove());
          });
      } else {
        window.alert(
          "You can only insert images under 10 MB inline in your content. You can other types of files below."
        );
      }
      return true;
    }
    return false;
  }

  _uploadImage(files) {
    const payload = new FormData();
    payload.append("authenticity_token", this._getCsrfToken());
    files.forEach((file) => payload.append("content_images[]", file));

    return fetch(`${this.element.dataset.contentImageUrl}.json`, {
      method: "POST",
      cache: "no-cache",
      credentials: "same-origin",
      body: payload,
    });
  }

  _getCsrfToken() {
    const meta = document.querySelector("meta[name=csrf-token]");
    return meta && meta.content;
  }

  _createImageHTML(view, event, data) {
    const image = document.createElement("img");
    const src = Object.values(data.urls)[0];
    const srcset = Object.keys(data.urls).map(
      (key) => `${data.urls[key]} ${key.replace(/\D/g, "")}w`
    );
    const sizes = "(max-width: 60ch) 100%; 60ch";
    image.src = src;
    image.onload = () => {
      const { schema } = view.state;
      const coordinates = view.posAtCoords({
        left: event.clientX,
        top: event.clientY,
      });
      const node = schema.nodes.image.create({
        id: data.id,
        src: src,
        srcset: srcset.join(", "),
        sizes: sizes,
        class: `align-${data.align}`,
      });
      const transaction = view.state.tr.insert(coordinates.pos, node);
      return view.dispatch(transaction);
    };
  }

  _updateContentImageIdList(images) {
    if (!this.hasContentImageIdsTarget) {
      return;
    }
    const ids = images.map((image) => image.id);
    this.contentImageIdsTarget.value +=
      (this.contentImageIdsTarget.value ? "," : "") + ids.join(",");
  }
}
