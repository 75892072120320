import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["post"];

  connect() {
    this.requiredIds = (this.element.dataset.requiredReadIds || "").split(",");
    this.postTargets.forEach((post) => this._setBadgeStatus(post));

    this.observer = new MutationObserver(this._updateNewItems.bind(this));
    this.observer.observe(this.element, {
      subtree: true,
      childList: true,
    });
  }

  disconnect() {
    this.observer.disconnect();
  }

  _updateNewItems(mutations) {
    for (const mutation of mutations) {
      [...mutation.addedNodes]
        .filter((node) => {
          return (
            node.nodeType === 1 &&
            (node.dataset["app-AcknowledgementBadgeTarget"] ||
              node.querySelector("[data-app--acknowledgement-badge-target]"))
          );
        })
        .forEach((node) => {
          const post = node.dataset["app-AcknowledgementBadgeTarget"]
            ? node
            : node.querySelector("[data-app--acknowledgement-badge-target]");
          this._setBadgeStatus(post);
        });
    }
  }

  _setBadgeStatus(post) {
    const hideAcknowledgedBadge = this.requiredIds.includes(
      post.dataset.postId
    );

    const requiredBadge = post.querySelector(
      "[data-acknowledgement-status-required]"
    );
    const acknowledgedBadge = post.querySelector(
      "[data-acknowledgement-status-acknowledged]"
    );
    // console.log(hideAcknowledgedBadge, requiredBadge, acknowledgedBadge);
    if (requiredBadge) requiredBadge.hidden = !hideAcknowledgedBadge;
    if (acknowledgedBadge) acknowledgedBadge.hidden = hideAcknowledgedBadge;
  }
}
