import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    scrollBody: { type: Boolean, default: false },
  };
  static targets = ["highlighted"];

  connect() {
    if (
      this.scrollBodyValue ||
      ["auto", "scroll"].includes(getComputedStyle(this.element).overflow)
    ) {
      this.highlightedTargets.forEach((target) =>
        target.scrollIntoView({ block: "nearest", inline: "start" })
      );
    }
  }
}
