const unitsOfTimeInSeconds = {
  years: 3600 * 24 * 365,
  months: 3600 * 24 * 30,
  weeks: 3600 * 24 * 7,
  days: 3600 * 24,
  hours: 3600,
  minutes: 60,
  seconds: 1,
};

/**
 * DateHelper
 * A Javascript port of the Rails DateHelper methods to get relative times.
 */
const DateHelper = {
  /**
   * Like DateHelper.distanceOfTimeInWords(), but where `toTime` is fixed to the current time.
   *
   * @param {Date} fromTime
   * @param {Object} [options={}]
   * @returns {String}
   */
  timeAgoInWords(fromTime) {
    // return this.distanceOfTimeInWords(fromTime, new Date(), options);
    const secondsElapsedFromNow = (fromTime.getTime() - Date.now()) / 1000;
    const secondsAgo = Math.abs(secondsElapsedFromNow);

    switch (true) {
      case secondsAgo <= 5:
        return "just now";
      case secondsAgo <= 15:
        return "about 10 seconds ago";
      case secondsAgo <= 25:
        return "about 20 seconds ago";
      case secondsAgo <= 35:
        return "about 30 seconds ago";
      case secondsAgo <= 59:
        return "under 1 minute ago";
    }

    const relativeTimeFormatter = new Intl.RelativeTimeFormat("en", {
      numeric: "auto",
    });

    // Loop through the units of time to find the most relevant unit
    for (const unitOfTime in unitsOfTimeInSeconds) {
      // If the seconds elapsed from now is greater than the unit of time in seconds,
      // use that unit of time.
      if (unitsOfTimeInSeconds[unitOfTime] <= secondsAgo) {
        // Convert the elapsed time from seconds to the unit of time
        const durationInUnitOfTime = Math.round(
          secondsElapsedFromNow / unitsOfTimeInSeconds[unitOfTime]
        );
        // Use Intl.RelativeTimeFormat to convert the duration to a relative time string
        return relativeTimeFormatter.format(durationInUnitOfTime, unitOfTime);
      }
    }
  },
};

export default DateHelper;
