import { Controller } from "@hotwired/stimulus";
import { createPopper } from "@popperjs/core";

export default class extends Controller {
  static targets = ["card"];
  static values = { url: String };

  show() {
    this.element.classList.add("active");
    if (this.hasCardTarget) {
      this.cardTarget.hidden = false;
      this.popper?.forceUpdate();
    } else {
      this.abortController = new AbortController();
      const signal = this.abortController.signal;

      fetch(this.urlValue, { signal })
        .then((r) => r.text())
        .then((html) => {
          const fragment = document
            .createRange()
            .createContextualFragment(html);

          this.element.appendChild(fragment);

          this.popper = createPopper(this.element, this.cardTarget, {
            placement: "auto",
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 3],
                },
              },
              {
                name: "arrow",
                options: {
                  padding: 20,
                },
              },
            ],
          });
        });
    }
  }

  hide() {
    this.element.classList.remove("active");
    if (this.abortController) this.abortController.abort();
    if (this.hasCardTarget) this.cardTarget.hidden = true;
  }

  disconnect() {
    if (this.hasCardTarget) this.cardTarget.remove();
  }
}
