import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["frame"];

  connect() {
    document.documentElement.addEventListener("turbo:frame-render", () => {
      if (!this.hasFrameTarget) return;

      const form = this.frameTarget.querySelector("form[data-resubmittable]");
      if (form) {
        form.dataset.remote = "true";
        form.requestSubmit();
        form.removeAttribute("data-resubmittable");
        form.removeAttribute("data-remote");
      }
    });
  }
}
