import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "input", "defaultSubmit"];

  connect() {
    if (!this.hasFormTarget) {
      return;
    }

    this.inputTargets.forEach((input) =>
      input.addEventListener("input", (evt) => this.submit())
    );

    if (this.hasDefaultSubmitTarget) {
      this.defaultSubmitTarget.hidden = true;
    }
  }

  submit() {
    this.formTarget.requestSubmit();
  }
}
