import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "startDate",
    "startTime",
    "endDate",
    "endTime",
    "includeTime",
    "timezone",
  ];

  connect() {}

  updateStartDate(evt) {
    if (!this.startPicker) {
      return;
    }

    if (
      (this.startDate && this.endDate && this.startDate > this.endDate) ||
      !this.startDate
    ) {
      this.startPicker.setDate(this.endDate);
    }
  }

  updateEndDate(evt) {
    if (!this.endPicker) {
      return;
    }

    if (
      (this.startDate && this.endDate && this.startDate > this.endDate) ||
      !this.endDate
    ) {
      this.endPicker.setDate(this.startDate);
    }

    this.endPicker.set("minDate", this.startDate);
  }

  //   updateStartTime(evt) {
  //     if (!this.hasStartTimeTarget) {
  //       return;
  //     }
  //
  //     if (!this.startTime) {
  //       this.startTimeTarget.value = this.endTime;
  //     }
  //
  //     if (this.startAndEndDatesMatch()) {
  //       this.startTimeTarget.setAttribute("max", this.endTime);
  //       if (this.compareStartTimeToEndTime("after")) {
  //         this.startTimeTarget.value = this.endTime;
  //       }
  //     } else {
  //       this.endTimeTarget.removeAttribute("max");
  //     }
  //   }
  //
  //   updateEndTime(evt) {
  //     if (!this.hasEndTimeTarget) {
  //       return;
  //     }
  //
  //     if (!this.endTime) {
  //       this.endTimeTarget.value = this.startTime;
  //     }
  //
  //     if (this.startAndEndDatesMatch()) {
  //       this.endTimeTarget.setAttribute("min", this.startTime);
  //       if (this.compareStartTimeToEndTime("after")) {
  //         this.endTimeTarget.value = this.startTime;
  //       }
  //     } else {
  //       this.endTimeTarget.removeAttribute("min");
  //     }
  //   }

  updateIncludeTime(evt) {
    const isAllDay = this.includeTimeTarget.checked;

    if (this.hasTimezoneTarget) {
      this.timezoneTarget.hidden = isAllDay;
    }
    if (this.hasStartTimeTarget) {
      this.startTimeTarget.hidden = isAllDay;
    }
    if (this.hasEndTimeTarget) {
      this.endTimeTarget.hidden = isAllDay;
    }
  }

  startAndEndDatesMatch() {
    if (!this.startDate || !this.endDate) {
      return false;
    }
    return this.startDate.getTime() === this.endDate.getTime();
  }

  compareStartTimeToEndTime(operator = "before") {
    if (!this.startTime || !this.endTime) {
      return true;
    }
    const parsedStartTime = parseInt(this.startTime.replace(/\D/g, ""));
    const parsedEndTime = parseInt(this.endTime.replace(/\D/g, ""));

    switch (operator) {
      case "before":
        return parsedStartTime < parsedEndTime;
      case "after":
        return parsedStartTime > parsedEndTime;
      default:
        return parsedStartTime === parsedEndTime;
    }
  }

  get startPicker() {
    if (this.hasStartDateTarget) {
      return this.startDateTarget._flatpickr;
    }
    return null;
  }

  get endPicker() {
    if (this.hasEndDateTarget) {
      return this.endDateTarget._flatpickr;
    }
    return null;
  }

  get startDate() {
    if (this.startPicker && this.startPicker.selectedDates.length) {
      return this.startPicker.selectedDates[0];
    }
    return null;
  }

  get startTime() {
    if (this.hasStartTimeTarget) {
      return this.startTimeTarget.value;
    }
    return "";
  }

  get endDate() {
    if (this.endPicker && this.endPicker.selectedDates.length) {
      return this.endPicker.selectedDates[0];
    }
    return null;
  }

  get endTime() {
    if (this.hasEndTimeTarget) {
      return this.endTimeTarget.value;
    }
    return "";
  }
}
