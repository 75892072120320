import { Controller } from "@hotwired/stimulus";
import FocusTrap from "../utils/focus-trap";

export default class extends Controller {
  static targets = ["trigger", "content"];

  connect() {
    this.triggerTargets.forEach((trigger) =>
      trigger.setAttribute("aria-expanded", false)
    );

    this.contentTargets.forEach((panel) => this._initPanelDOM(panel));

    document.addEventListener(
      "turbo:load",
      this._openPrerequetedPanel.bind(this)
    );

    window.addEventListener(
      "DOMContentLoaded",
      this._openPrerequetedPanel.bind(this)
    );
  }

  disconnect() {
    document.removeEventListener(
      "turbo:load",
      this._openPrerequetedPanel.bind(this)
    );

    window.removeEventListener(
      "DOMContentLoaded",
      this._openPrerequetedPanel.bind(this)
    );
  }

  toggle(evt) {
    const link = evt.target.closest("a");
    const button = evt.target.closest("button");
    if (button || (link && !link.matches("[data-turbo-frame]"))) {
      evt.preventDefault();
    }
    const trigger = evt.currentTarget;
    this._setPanelOpenState(
      trigger,
      trigger.getAttribute("aria-expanded") !== "true"
    );
  }

  open(evt) {
    evt.preventDefault();
    this._setPanelOpenState(evt.currentTarget, true);
  }

  close(evt) {
    evt.preventDefault();
    this._setPanelOpenState(evt.currentTarget, false);
  }

  _setPanelOpenState(trigger, shouldOpen) {
    const panel = document.getElementById(
      trigger.getAttribute("aria-controls")
    );
    if (!panel) {
      return;
    }

    if (shouldOpen) {
      this._openPanel(panel);
    } else {
      this._closePanel(panel);
    }
  }

  _openPanel(panel) {
    this._closePanel();
    panel.dataset.open = true;
    this._activePanel = panel;
    this._setTriggerState(panel);
    this._setFocus(panel);
    document.body.addEventListener("keyup", this._bindKeyEvents.bind(this));
  }

  _closePanel(panel = this._activePanel) {
    if (!panel) {
      return;
    }

    panel.dataset.open = false;
    document.body.removeEventListener("keyup", this._bindKeyEvents.bind(this));
    this._setTriggerState(panel);

    if (this._focusTrap) {
      this._focusTrap.release();
      this._focusTrap = null;
    }
  }

  _bindKeyEvents(e) {
    switch (e.key) {
      case "Escape":
        this._closePanel();
        break;
      default:
        break;
    }
  }

  _setFocus(panel) {
    this._focusTrap = new FocusTrap(panel);
    this._focusTrap.set();
    this._focusTrap.highlightFirstFocusableElement();
  }

  _setTriggerState(panel) {
    this.triggerTargets
      .filter((trigger) => trigger.getAttribute("aria-controls") === panel.id)
      .forEach((trigger) =>
        trigger.setAttribute("aria-expanded", panel.dataset.open)
      );
  }

  _openPrerequetedPanel() {
    if (!window.location.hash) {
      return;
    }
    const requestedElement = document.querySelector(window.location.hash);
    if (!requestedElement) {
      return;
    }

    const requestedPanel =
      requestedElement.dataset.drawerTarget === "content"
        ? requestedElement
        : requestedElement.closest('[data-drawer-target="content"]');

    if (requestedPanel) {
      history.replaceState("", document.title, window.location.pathname);
      window.setTimeout(() => {
        this._openPanel(requestedPanel);
        if (requestedElement !== requestedPanel) {
          const scrollToElement = () => {
            requestedElement.scrollIntoView();
            requestedPanel.removeEventListener(
              "transitionend",
              scrollToElement
            );
          };

          requestedPanel.addEventListener("transitionend", scrollToElement);
        }
      }, 500);
    }
  }

  _initPanelDOM(panel) {
    if (panel.dataset.initialized) {
      return;
    }

    let drawerPanel;

    if (panel.dataset.drawerDuplicate === "true") {
      const duplicatePanel = panel.cloneNode(true);
      panel.removeAttribute("id");
      Object.keys(panel.dataset).forEach((key) => {
        if (key.indexOf("drawer") > -1) {
          delete panel.dataset[key];
        }
      });
      panel.dataset.duplicated = "true";
      drawerPanel = duplicatePanel;
    } else {
      drawerPanel = panel;
    }

    const wrapper = document.createElement("div");
    wrapper.className = drawerPanel.className;
    wrapper.classList.add("drawer-content");
    drawerPanel.className = "";

    drawerPanel.appendChild(wrapper);
    Array.from(drawerPanel.childNodes)
      .filter((child) => child !== wrapper)
      .forEach((child) => wrapper.appendChild(child));

    // Create a close button for the panel
    drawerPanel.insertAdjacentHTML(
      "afterbegin",
      `<header class="drawer-header">${
        drawerPanel.dataset.drawerHeading
          ? '<h3 class="h4 title truncatable">' +
            drawerPanel.dataset.drawerHeading +
            "</h3>"
          : ""
      }<button type="button" class="close" data-action="click->drawer#close" aria-controls="${
        drawerPanel.id
      }"><span class="sr-only">${
        drawerPanel.dataset.labelClose || "Close"
      }</span></button></header>`
    );

    // Move panels to the bottom of the document to fix any positioning or z-index issues.
    if (drawerPanel.dataset.drawerRelocate !== "false") {
      document.body.insertAdjacentElement("beforeend", drawerPanel);
    }

    // We're done
    drawerPanel.dataset.initialized = true;
  }
}
