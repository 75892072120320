const CANDIDATES = `
a, button, input:not([type="hidden"]), select, textarea, area, details, summary,
iframe, object, embed,
[tabindex], [contenteditable]
`;

class FocusTrap {
  constructor(focusNode, rootNode = document) {
    this.focusNode = focusNode;
    this.rootNode = rootNode;
    this.nodes = [];
  }

  set() {
    this.nodes = Array.from(this.rootNode.querySelectorAll(CANDIDATES)).filter(
      (node) =>
        !this.focusNode.contains(node) && node.getAttribute("tabindex") !== "-1"
    );
    this.nodes.forEach((node) => node.setAttribute("tabindex", "-1"));
  }

  release() {
    this.nodes.forEach((node) => node.removeAttribute("tabindex"));
    this.nodes = [];
  }

  highlightFirstFocusableElement() {
    const firstFocusable = this.focusNode.querySelector(CANDIDATES);
    if (firstFocusable) {
      window.setTimeout(() => {
        firstFocusable.focus();
      }, 10);
    }
  }
}

export default FocusTrap;
