import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "counter"];

  initialize() {
    this.update = this.update.bind(this);
  }

  connect() {
    this.maxlength = parseInt(this.inputTarget.getAttribute("maxlength"), 10);
    this.inputTarget.addEventListener("input", this.update);
    this.update();
  }

  disconnect() {
    this.inputTarget.removeEventListener("input", this.update);
  }

  update() {
    this.counterTarget.innerHTML = (this.maxlength - this.count).toString();
  }

  get count() {
    return this.inputTarget.value.length;
  }
}
