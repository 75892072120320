import tippy from "tippy.js";

let selectedIndex = 0;

export default {
  render() {
    let component;
    let popup;
    let items;
    let clientRect;
    let command;

    const buildResultHTML = () => {
      if (!Array.isArray(items)) {
        return null;
      }
      if (!items.length) {
        return `
          <div class="items">
            <div class="item">
              No results
            </div>
          </div>`;
      }
      return `
        <div class="items stack-flush">
          ${items.map(resultMarkup).join("")}
        </div>`;
    };

    const resultMarkup = (item, index) => {
      return `
        <button
          class="item ${index === selectedIndex ? "selected" : ""}"
        >
          ${item.content}
        </button>`;
    };

    const selectItem = (index) => {
      const item = items[index];

      if (item && command) {
        command({ id: item.hashid, label: item.name });
      }
    };

    const handlePopupClick = (evt) => {
      const clickedButton = evt.target.closest("button.item");
      if (!clickedButton) {
        return;
      }
      const buttons = [...popup[0].popper.querySelectorAll("button.item")];
      selectItem(buttons.indexOf(clickedButton));
    };

    const renderResults = () => {
      component = buildResultHTML();

      if (!popup) {
        popup = tippy("body", {
          getReferenceClientRect: clientRect,
          appendTo: () => document.body,
          content: component,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start",
          allowHTML: true,
          theme: "groupnews-mentions",
        });
        popup[0].popper.addEventListener("click", handlePopupClick);
      } else {
        popup[0].setContent(component);
      }
      popup[0].setProps({
        getReferenceClientRect: clientRect,
      });
    };

    return {
      onStart: (props) => {},

      onUpdate(props) {
        if (!props.clientRect) {
          return;
        }
        selectedIndex = 0;
        items = props.items;
        clientRect = props.clientRect;
        command = props.command;

        renderResults();
      },

      onKeyDown(props) {
        switch (props.event.key) {
          case "Escape":
            popup[0].hide();
            return true;
          case "ArrowUp":
            selectedIndex = (selectedIndex + items.length - 1) % items.length;
            renderResults();
            return true;
          case "ArrowDown":
            selectedIndex = (selectedIndex + 1) % items.length;
            renderResults();
            return true;
          case "Enter":
            selectItem(selectedIndex);
            return true;
          default:
            return false;
        }
      },

      onExit() {
        if (popup && popup.length) {
          popup[0].destroy();
        }
        popup = null;
        items = null;
        clientRect = null;
        command = null;
      },
    };
  },

  async items({ query }) {
    return await fetch(`/users/mentions.json?query=${query}`)
      .then((response) => response.json())
      .then((users) => users)
      .catch((error) => []);
  },
};
