// Options:
//
// Use data-pricing-active="yearly" to select yearly by default

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggle", "frequency", "plans"];

  connect() {
    // Hide frequency toggle if we have less than 2 frequencies with plans
    if (this.frequencyTargets.length < 2) this._hideFrequencyToggle();

    const frequencies = [].map.call(
      this.frequencyTargets,
      (el) => el.dataset.frequency
    );
    this.frequencies = [...new Set(frequencies)];

    const frequency =
      this.data.get("active") || this.frequencyTargets[0].dataset.frequency;

    this._toggle(frequency);
  }

  // Switches visible plans
  switch(event) {
    event.preventDefault();
    let frequency = event.target.dataset.frequency || this._getNextFrequency();
    this._toggle(frequency);

    document.dispatchEvent(
      new CustomEvent("pricing:frequency-updated", {
        bubbles: true,
        cancelable: true,
        frequency: frequency,
      })
    );
  }

  _getNextFrequency() {
    return this.frequencies.filter((f) => this.data.get("active") !== f)[0];
  }

  // Hides frequency toggle
  _hideFrequencyToggle() {
    this.toggleTarget.hidden = true;
  }

  // Toggles visible plans and selected frequency
  // Expects: "monthly", "yearly", etc
  _toggle(frequency) {
    // Keep track of active frequency on a data attribute
    this.data.set("active", frequency);

    this.plansTargets.forEach((target) => {
      if (target.dataset.frequency == frequency) {
        this.showPlans(target);
      } else {
        this.hidePlans(target);
      }
    });
  }

  showPlans(element) {
    element.hidden = false;
  }

  hidePlans(element) {
    element.hidden = true;
  }
}
