import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"];

  connect() {
    this.isWindows = navigator?.appVersion?.includes("Win");
    this.isMac = navigator?.appVersion?.includes("Mac");

    if (!this.isWindows && !this.isMac) {
      return;
    }

    this.commandKeyActive = false;
    this.addListeners();
  }

  disconnect() {
    this.removeListeners();
  }

  addListeners() {
    window.addEventListener("keydown", this.bindCommandKeyListener.bind(this));
    window.addEventListener("keyup", this.releaseCommandKey.bind(this));
    window.addEventListener("keydown", this.bindSaveCommandListener.bind(this));
  }

  removeListeners() {
    window.removeEventListener("keydown", this.bindCommandKeyListener);
    window.removeEventListener("keyup", this.releaseCommandKey);
    window.removeEventListener("keydown", this.bindSaveCommandListener);
  }

  bindCommandKeyListener(evt) {
    if ((this.isWindows && evt.ctrlKey) || (this.isMac && evt.metaKey)) {
      this.commandKeyActive = true;
    }
  }

  releaseCommandKey(evt) {
    this.commandKeyActive = false;
  }

  bindSaveCommandListener(evt) {
    if (this.commandKeyActive && evt.code === "KeyS") {
      evt.preventDefault();
      this.formTarget.dispatchEvent(
        new Event("submit", {
          bubbles: true,
          cancelable: true,
        })
      );
    }
  }
}
