import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    endTime: String,
  };
  static targets = ["days", "hours", "minutes", "seconds"];

  connect() {
    if (!this.endTimeValue) {
      return;
    }
    this._updateClock();
    this.timeInterval = setInterval(this._updateClock.bind(this), 1000);
  }

  disconnect() {
    if (this.timeInterval) {
      clearInterval(this.timeInterval);
    }
  }

  _updateClock() {
    const t = this._getTimeRemaining();

    this.daysTarget.innerHTML = String(t.days).padStart(2, "0");
    this.hoursTarget.innerHTML = String(t.hours).padStart(2, "0");
    this.minutesTarget.innerHTML = String(t.minutes).padStart(2, "0");
    this.secondsTarget.innerHTML = String(t.seconds).padStart(2, "0");

    if (this.timeInterval && t.total <= 0) {
      clearInterval(this.timeinterval);
    }
  }

  _getTimeRemaining() {
    const total = Date.parse(this.endTimeValue) - Date.parse(new Date());
    const seconds = Math.max(0, Math.floor((total / 1000) % 60));
    const minutes = Math.max(0, Math.floor((total / 1000 / 60) % 60));
    const hours = Math.max(0, Math.floor((total / (1000 * 60 * 60)) % 24));
    const days = Math.max(0, Math.floor(total / (1000 * 60 * 60 * 24)));
    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    };
  }
}
