import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["trigger"];

  connect() {
    this.storageKey = "_togglerHiddenIds";
    this._recallTogglerState();
  }

  toggle(evt) {
    evt.preventDefault();
    const toggler = evt.currentTarget;
    const [targetIds, toggleable] = this._getToggleablesFor(toggler);
    const shouldShow = toggler.ariaExpanded === "false";
    toggler.ariaExpanded = shouldShow;

    toggleable.forEach((el) => {
      el.hidden = !el.hidden;
      if (el.hidden) {
        this.element.removeAttribute("data-toggled-ids");
      } else {
        this.element.dataset.toggledIds = targetIds;
      }
    });
    this._updateTogglerLabels(toggler);
    this._saveTogglerState(targetIds, !shouldShow);
  }

  close(evt) {
    if (evt.preventDefault) {
      evt.preventDefault();
    }
    const toggler = evt.currentTarget;
    const [targetIds, toggleable] = this._getToggleablesFor(toggler);
    toggler.ariaExpanded = false;
    toggleable.forEach((el) => (el.hidden = true));
    this.element.removeAttribute("data-toggled-ids");
    this._updateTogglerLabels(toggler);
    this._saveTogglerState(targetIds, true);
  }

  _getToggleablesFor(toggler) {
    const targetIds = toggler.getAttribute("aria-controls");
    const toggleable = document.querySelectorAll(
      targetIds
        .split(/\s/)
        .map((id) => `#${id}`)
        .join(",")
    );
    return [targetIds, toggleable];
  }

  _updateTogglerLabels(toggler) {
    if (!toggler.dataset.toggleLabelClose || !toggler.dataset.toggleLabelOpen) {
      return;
    }
    toggler.innerText =
      toggler.innerText.trim().toLowerCase() ===
      toggler.dataset.toggleLabelOpen.trim().toLowerCase()
        ? toggler.dataset.toggleLabelClose
        : toggler.dataset.toggleLabelOpen;
  }

  _saveTogglerState(ids, closed) {
    const idsToUpdate = ids.split(/\s/);
    let updatedIds;
    if (closed) {
      updatedIds = this._hiddenIds().concat(idsToUpdate);
    } else {
      updatedIds = this._hiddenIds().filter((id) => !idsToUpdate.includes(id));
    }
    localStorage.setItem(
      this.storageKey,
      [...new Set(updatedIds.filter(Boolean))].join(",")
    );
  }

  _recallTogglerState() {
    this.triggerTargets.forEach((trigger) => {
      trigger
        .getAttribute("aria-controls")
        .split(/\s/)
        .forEach((id) => {
          if (this._hiddenIds().includes(id)) {
            this.close({ currentTarget: trigger });
          }
        });
    });
  }

  _hiddenIds() {
    return (localStorage.getItem(this.storageKey) || "").split(",");
  }
}
