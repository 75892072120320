import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["buttons", "countdown"];

  connect() {
    const timeoutSeconds = parseInt(this.data.get("timeout"));

    if (this.isPreview) {
      return;
    }

    setTimeout(() => {
      this.element.classList.add("active");

      // Trigger countdown
      if (this.hasCountdownTarget) {
        this.countdownTarget.style.animationDuration = `${timeoutSeconds}s`;
      }
    }, 200);
    this.timeoutId = setTimeout(() => {
      this.close();
    }, timeoutSeconds * 1000 + 200);
  }

  run(e) {
    e.preventDefault();
    this.stop();
    let _this = this;
    this.buttonsTarget.innerHTML =
      '<span class="font-size:small">Processing...</span>';

    // Call the action
    fetch(this.data.get("action-url"), {
      method: this.data.get("action-method").toUpperCase(),
      dataType: "script",
      credentials: "include",
      headers: {
        "X-CSRF-Token": this.csrfToken,
      },
    }).then(function (response) {
      let content;

      // Example of the response, content should be provided from the controller
      if (response.status === 200) {
        content = '<span class="font-size:small">Done</span>';
      } else {
        content = '<span class="font-size:small error">Error</span>';
      }

      // Set new content
      _this.buttonsTarget.innerHTML = content;

      // Close
      setTimeout(() => {
        _this.close();
      }, 1000);
    });
  }

  stop() {
    clearTimeout(this.timeoutId);
    this.timeoutId = null;
  }

  close() {
    // Remove with transition
    this.element.classList.remove("active");
    this.element.addEventListener("transitionend", () => this.element.remove());
  }

  get isPreview() {
    return document.documentElement.hasAttribute("data-turbolinks-preview");
  }

  get csrfToken() {
    const element = document.head.querySelector('meta[name="csrf-token"]');
    return element.getAttribute("content");
  }
}
