import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["updatable"];

  connect() {}

  disable(evt) {
    this.updatableTargets.forEach((element) => (element.disabled = true));
  }
}
