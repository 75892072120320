import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["trigger", "updatable"];

  connect() {
    this.url = this.data.get("url");
    this.method = this.data.get("method") || "GET";
    if (this.hasTriggerTarget) {
      this.setUrl({ target: this.triggerTarget });
      this.makeRequest();
    }
  }

  makeRequest() {
    if (!this.url) {
      return;
    }

    fetch(this.url)
      .then((response) => response.text())
      .then(this.success.bind(this));
  }

  setUrl(evt) {
    const target = evt.target;
    if (target.dataset.url) {
      this.url = target.dataset.url;
      return;
    }
    if (
      target.matches("select") &&
      target.options[target.selectedIndex].dataset.url
    ) {
      this.url = target.options[target.selectedIndex].dataset.url;
    }
  }

  getUrl() {
    this.data.get("url");
  }

  success(html) {
    this.updatableTarget.innerHTML = html;
  }

  error(data) {
    console.dir(data);
  }
}
