let container;

const initSprites = () => {
  container = document.querySelector("[data-sprite-urls]");
  if (!container) {
    return;
  }
  const urls = JSON.parse(container.dataset.spriteUrls);
  urls.forEach((url) => loadSprite(url));
};

const loadSprite = (url) => {
  fetch(url)
    .then((response) => response.text())
    .then((sprite) => container.insertAdjacentHTML("beforeend", sprite));
};

document.addEventListener("turbo:load", () => {
  initSprites();
});
