import { Controller } from "@hotwired/stimulus";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import PhotoSwipeDynamicCaption from "photoswipe-dynamic-caption-plugin";

const arrowSVG =
  '<svg height="60" viewBox="0 0 60 60" width="60" class="pswp__icn" xmlns="http://www.w3.org/2000/svg"><path d="m19.6489322 32.5150925 7.0759582 7.1101302c.4892461.4725173.7671051 1.123988.7718475 1.808901.0071251.6825304-.2612486 1.3387908-.7410222 1.8208753-.4797432.48206-1.1328739.7517487-1.8121239.745001-.6816213-.0051728-1.3299853-.2843846-1.8002073-.7759751l-11.4006143-11.4556715c-.9903603-.9927603-.9903603-2.6059806 0-3.598741l11.4006143-11.4556714c.9927314-.9641078 2.5721155-.9498121 3.5505591.0310236.9761332.9832298.9903602 2.5701802.0308745 3.5677058l-7.0759582 7.1101302h26.8170242c1.3988709 0 2.5341159 1.1407275 2.5341159 2.546354s-1.135245 2.546354-2.5341159 2.546354z"></svg>';

const closeSVG =
  '<svg height="32" viewBox="0 0 32 32" width="32" class="pswp__icn" xmlns="http://www.w3.org/2000/svg"><path d="m24.6211146 22.7923933-6.7923933-6.7923933 6.7923933-6.79239329c.5051805-.50518059.5051805-1.32354067 0-1.82872127-.5051806-.50518059-1.3235407-.50518059-1.8287213 0l-6.7923933 6.79239326-6.79239329-6.79239326c-.50518059-.50518059-1.32354067-.50518059-1.82872127 0-.50518059.5051806-.50518059 1.32354068 0 1.82872127l6.79239326 6.79239329-6.79239326 6.7923933c-.50518059.5051806-.50518059 1.3235407 0 1.8287213.5051806.5051805 1.32354068.5051805 1.82872127 0l6.79239329-6.7923933 6.7923933 6.7923933c.5051806.5051805 1.3235407.5051805 1.8287213 0 .5051805-.5051806.5051805-1.3235407 0-1.8287213z"></svg>';

export default class extends Controller {
  connect() {
    const pswpOptions = this.pswpOptions();
    const lightbox = new PhotoSwipeLightbox({
      gallery: this.element,
      children: "[data-pswp-width]",
      pswpModule: () => import("photoswipe"),
      ...this.pswpOptionsValue,
      ...pswpOptions,
    });

    // https://photoswipe.com/events/
    if (pswpOptions.handlers !== undefined) {
      Object.entries(pswpOptions.handlers).forEach(([eventName, handlers]) => {
        for (let i = 0; i < handlers.length; i++) {
          const handler = handlers[i].bind(this);
          lightbox.on(eventName, handler);
        }
      });
    }

    // https://photoswipe.com/filters/
    if (pswpOptions.filters !== undefined) {
      Object.entries(pswpOptions.filters).forEach(([eventName, filters]) => {
        for (let i = 0; i < filters.length; i++) {
          const filter = filters[i].bind(this);
          lightbox.addFilter(eventName, filter);
        }
      });
    }

    // Initialize lightbox, should be called only once, it's not included in
    // the main constructor, so you may bind events before it.
    lightbox.init();
    this.lightbox = lightbox;
    this.caption = new PhotoSwipeDynamicCaption(lightbox, {
      captionContent: (slide) => {
        const caption =
          slide.data.element.querySelector(".pswp-caption")?.innerHTML ||
          slide.data.element.querySelector("figcaption")?.innerHTML;
        return caption;
      },
    });
  }

  disconnect() {
    if (this.lightbox) {
      // Unbinds all events, closes PhotoSwipe if it's open
      this.lightbox.destroy();
      this.lightbox = undefined;
    }
  }

  pswpOptions() {
    // https://photoswipe.com/options/
    return {
      bgOpacity: 0.85,
      arrowPrevSVG: arrowSVG,
      arrowNextSVG: arrowSVG,
      closeSVG: closeSVG,
      showAnimationDuration: 200,
      hideAnimationDuration: 200,
      easing: "cubic-bezier(0.65, 0, 0.35, 1)",
      paddingFn: (viewportSize) => {
        return {
          top: 18,
          bottom: 18,
          left: 56,
          right: 56,
        };
      },

      // https://photoswipe.com/events/
      handlers: {
        beforeOpen: [this.beforeOpenHandler],
      },

      // https://photoswipe.com/filters/
      filters: {
        // numItems: [this.numItemsFilter]
      },
    };
  }

  beforeOpenHandler() {
    console.log("beforeOpen");
  }

  numItemsFilter(numItems, dataSource) {
    console.log("numItems");
    return numItems;
  }
}
