import { Controller } from "@hotwired/stimulus";

const capitalize = (str) =>
  str.substring(0, 1).toUpperCase() + str.substring(1);

export default class extends Controller {
  static targets = [
    "frequency",
    "optionGroup",
    "yearly",
    "monthly",
    "weekly",
    "daily",
    "endOptionSelect",
    "endOption",
    "endAfter",
    "endOn",
  ];

  connect() {
    const preselected = this.frequencyTargets.filter((input) => input.checked);
    if (preselected.length) {
      this.displayOptionGroup(preselected[0].value);
    }
    this.displayEndOption(this.endOptionSelectTarget.value);
  }

  selectFrequency(event) {
    this.displayOptionGroup(event.target.value);
  }

  displayOptionGroup(group) {
    this.optionGroupTargets.forEach((group) => (group.hidden = true));
    const target = this[`${group}Target`];
    if (target) {
      target.hidden = false;
    }
  }

  selectEndOption(event) {
    this.displayEndOption(event.target.value);
  }

  displayEndOption(value) {
    this.endOptionTargets.forEach((option) => (option.hidden = true));
    const target = this[`end${capitalize(value)}Target`];
    if (target) {
      target.hidden = false;
    }
  }
}
