// import Sortable from "stimulus-sortable";
//
// export default class extends Sortable {
//   connect() {
//     super.connect();
//
//     //     // The sortable.js instance.
//     //     this.sortable
//     //
//     //     // Your options
//     //     this.options
//     //
//     //     // Your default options
//     //     this.defaultOptions
//   }
//
//   // You can override the `end` method here.
//   // end({ item, newIndex }) {
//   //   super.end({ item, newIndex })
//   // }
//
//   // You can set default options in this getter for all sortable elements.
//   get defaultOptions() {
//     return {
//       chosenClass: "chosen",
//       ghostClass: "ghost",
//       dragClass: "dragging",
//     };
//   }
//
//   get responseKindValue() {
//     return "json";
//   }
// }
