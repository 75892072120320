// This is for Safari to request the proper srcset image on resize or turbo load because it's stupid and lazy about it.

const isSafari =
  navigator.vendor &&
  navigator.vendor.indexOf("Apple") > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf("CriOS") == -1 &&
  navigator.userAgent.indexOf("FxiOS") == -1;

const reloadSrcsetImage = (evtOrElement) => {
  const scope =
    typeof evtOrElement.querySelectorAll === "function"
      ? evtOrElement
      : document;
  scope.querySelectorAll("img[srcset]").forEach((img) => (img.src += ""));
};

const initStreamContainers = () => {
  document
    .querySelectorAll("[data-defibrillate-images]")
    .forEach((container) => {
      const observer = new MutationObserver((mutationList, observer) => {
        mutationList.forEach((mutation) => {
          if (mutation.type === "childList" || mutatationType === "subtree") {
            reloadSrcsetImage(container);
          }
        });
      });
      observer.observe(container, {
        childList: true,
        subtree: true,
        attributes: false,
      });
    });
};

if (isSafari) {
  document.addEventListener("turbo:load", reloadSrcsetImage);
  document.addEventListener("turbo:load", initStreamContainers);
  window.addEventListener("resize", reloadSrcsetImage);
}
