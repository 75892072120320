// Example usage:
// <div data-controller="tooltip" data-tippy-content="Hello world"></div>

import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";

export default class extends Controller {
  connect() {
    const ariaLabel = this.element.getAttribute("aria-label");
    if (ariaLabel) {
      this.element.dataset.tippyContent = ariaLabel;
    }
    if (this.element.dataset.tooltipContent) {
      this.element.dataset.tippyContent = this.element.dataset.tooltipContent;
      delete this.element.dataset.tooltipContent;
    }
    if (this.element.dataset.tooltipPlacement) {
      this.element.dataset.tippyPlacement =
        this.element.dataset.tooltipPlacement;
      delete this.element.dataset.tooltipPlacement;
    }
    this.tippy = tippy(this.element, {
      theme: "groupnews",
      animation: "shift-toward-subtle",
      placement: "auto",
      offset: [0, 10],
      duration: [100, 200],
      delay: [100, null],
    });
  }

  disconnect() {
    this.tippy.destroy();
  }
}
