import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "submit"];

  connect() {
    if (!this.hasSubmitTarget) {
      return;
    }
    this.form = this.hasFormTarget ? this.formTarget : this.element;
    this.check();
  }

  check() {
    this.submitTargets.forEach(
      (target) => (target.disabled = !this.form.checkValidity())
    );
  }
}
