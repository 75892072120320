/*
  Modal dialogs need an HTML structure similar to this

  <div data-controller="modal">
    <button type="button" data-modal-target="trigger" data-action="click->modal#open" aria-expanded="false" aria-controls="my-modal" class="btn">Open The Modal</button>

    <section id="my-modal" class="modal-dialog stack" role="dialog" aria-labelledby="my-modal-title" aria-describedby="my-modal-description" hidden>
      <header class="header">
       <h4 id="my-modal-title">Modal Title</h4>
       <p id="my-modal-description" class="sr-only">Modal Description</p>
      </header>
      <div class="content">
        Whatever you want
      </div>
      <button type="button" class="close" data-action="click->modal#close"><span class="sr-only"><%= t('close_dialog') %></span></button>
    </section>
  </div>
 */

import { Controller } from "@hotwired/stimulus";
import FocusTrap from "../utils/focus-trap";
import { enter, leave } from "../utils/el-transition";

export default class extends Controller {
  static values = {
    closeOnBackgroundClick: { type: Boolean, default: true },
  };
  static targets = ["trigger"];

  connect() {
    if (!this.hasTriggerTarget) {
      return;
    }
    this.trigger = this.triggerTarget;
    this.dialog = document.getElementById(
      this.trigger.getAttribute("aria-controls")
    );

    if (!this.dialog) {
      return;
    }

    // Set up the focus trap
    this.focusTrap = new FocusTrap(this.dialog);

    // Move dialog to the bottom of the document to fix any positioning or z-index issues.
    document.body.insertAdjacentElement("beforeend", this.dialog);

    // We need to do this because we move the dialog out of the controller container.
    this.dialog
      .querySelectorAll('[data-action="click->modal#close"]')
      .forEach((close) =>
        close.addEventListener("click", this.close.bind(this))
      );

    this.underlay = document.createElement("div");
    this.underlay.className = "modal-dialog-underlay";
    if (this.closeOnBackgroundClickValue) {
      this.underlay.addEventListener("click", this.close.bind(this));
    }
    this.dialog.insertAdjacentElement("afterend", this.underlay);
  }

  async open(evt) {
    if (this.trigger.getAttribute("aria-expanded") === "true") {
      this.close();
      return;
    }
    this.focusedElBeforeOpen = document.activeElement;
    await enter(this.dialog);
    this.focusTrap.set();
    if (!this.trigger.dataset.modalPreventAutofocus) {
      this.focusTrap.highlightFirstFocusableElement();
    }
    this.trigger.setAttribute("aria-expanded", true);
    document.body.addEventListener("keyup", this.bindKeyEvents.bind(this));
    this.watchTurboFrameLoad(this.trigger.dataset.modalCloseOnLoad);
    if (this.trigger.dataset.reloadFrame) {
      this.reloadOnCloseFrame = document.getElementById(
        this.trigger.dataset.reloadFrame
      );
    }
  }

  async close() {
    await leave(this.dialog);
    this.focusTrap.release();
    this.trigger.setAttribute("aria-expanded", false);
    document.body.removeEventListener("keyup", this.bindKeyEvents.bind(this));
    if (typeof this.reloadOnCloseFrame?.reload === "function") {
      this.reloadOnCloseFrame.reload();
    }
    this.unwatchTurboFrameLoad();

    if (this.focusedElBeforeOpen) {
      window.setTimeout(() => {
        this.focusedElBeforeOpen.focus();
      }, 10);
    }
  }

  bindKeyEvents(e) {
    switch (e.key) {
      case "Escape":
        this.close();
        break;
      default:
        break;
    }
  }

  watchTurboFrameLoad(closerFrameSelector) {
    this.turboFrame = this.dialog.querySelector("turbo-frame");
    if (this.turboFrame) {
      this.turboFrame.addEventListener(
        "turbo:frame-load",
        this.highlightFocusable.bind(this)
      );
    }

    if (closerFrameSelector) {
      this.closerTurboFrame = document.getElementById(closerFrameSelector);
      if (this.closerTurboFrame) {
        this.closerTurboFrame.addEventListener(
          "turbo:frame-load",
          this.close.bind(this)
        );
      }
    }
  }

  unwatchTurboFrameLoad() {
    if (this.turboFrame) {
      this.turboFrame.removeEventListener(
        "turbo:frame-load",
        this.highlightFocusable.bind(this)
      );
    }
    if (this.closerTurboFrame) {
      this.closerTurboFrame.removeEventListener(
        "turbo:frame-load",
        this.close.bind(this)
      );
    }
  }

  highlightFocusable(evt) {
    this.focusTrap.highlightFirstFocusableElement();
  }
}
