import { Controller } from "@hotwired/stimulus";

const AcceptHeaders = {
  "*": "*/*",
  text: "text/plain",
  html: "text/html",
  xml: "application/xml, text/xml",
  json: "application/json, text/javascript",
  script:
    "text/javascript, application/javascript, application/ecmascript, application/x-ecmascript",
};

export default class extends Controller {
  static targets = ["form", "status", "timestamp", "submit"];
  static values = {
    duration: Number,
    labelSaving: String,
    labelSaved: String,
    labelSaveError: String,
  };

  connect() {
    this.timeout = null;
    this.duration = this.durationValue || 1500;
    this.labels = {
      saving: this.labelSavingValue || "Saving...",
      saved: this.labelSavedValue || "Saved",
      error: this.labelSaveError || "Unable to save",
    };

    if (this.hasSubmitTarget) {
      this.submitTarget.disabled = true;
    }
  }

  save(evt) {
    clearTimeout(this.timeout);

    if (this.hasSubmitTarget) {
      this.submitTarget.disabled = false;
    }

    this.timeout = setTimeout(
      () => {
        if (!this.formTarget.checkValidity()) {
          return;
        }
        if (evt?.target?.dataset?.autosaveRemote === "false") {
          this.formTarget.removeAttribute("data-remote");
        } else {
          this.formTarget.dataset.remote = "true";
        }
        this.formTarget.dispatchEvent(
          new Event("submit", {
            bubbles: true,
            cancelable: true,
          })
        );
      },
      evt.type === "keyup" || evt.type === "input" ? this.duration : 0
    );
  }

  success(evt) {
    this.formTarget.removeAttribute("data-remote");
    const payload = evt.detail[0];
    if (this.hasTimestampTarget && payload?.data?.updated_at) {
      this.timestampTarget.setAttribute("datetime", payload.data.updated_at);
    }
    if (payload.html) this.updateTargets(payload.html);
    if (payload.element_classes)
      this.updateElementClasses(payload.element_classes);
    if (payload.element_attributes)
      this.updateElementAttributes(payload.element_attributes);
    this.setStatus(this.labels.saved);
  }

  error() {
    this.formTarget.removeAttribute("data-remote");
    if (!this.hasStatusTarget) {
      return;
    }
    this.statusTarget.classList.add("error");
    this.setStatus(this.labels.error);
  }

  setStatus(message) {
    if (!this.hasStatusTarget) {
      return;
    }
    this.statusTarget.textContent = message;

    this.timeout = setTimeout(() => {
      this.statusTarget.textContent = "";
      this.statusTarget.classList.remove("error");
    }, 2500);

    if (this.hasSubmitTarget) {
      this.submitTarget.disabled = true;
    }
  }

  updateTargets(htmlBlocks = {}) {
    for (const [key, block] of Object.entries(htmlBlocks)) {
      const targetClass = this.dasherize(key);
      document
        .querySelectorAll(`.${targetClass}[data-updateable-target]`)
        .forEach((el) => (el.innerHTML = block));
    }
  }

  updateElementClasses(elements = {}) {
    for (const [key, classes] of Object.entries(elements)) {
      const targetClass = this.dasherize(key);
      document
        .querySelectorAll(`.${targetClass}[data-updateable-classes]`)
        .forEach((el) =>
          el.setAttribute("class", [targetClass, classes].join(" "))
        );
    }
  }

  updateElementAttributes(elements = {}) {
    for (const [key, attributes] of Object.entries(elements)) {
      const targetClass = this.dasherize(key);
      document.querySelectorAll(`.${targetClass}`).forEach((el) => {
        for (const [attribute, value] of Object.entries(attributes)) {
          el.setAttribute(this.dasherize(attribute), value);
        }
      });
    }
  }

  dasherize(str) {
    return str.replace(/_/g, "-");
  }
}
