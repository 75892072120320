import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="auto-search-form"
export default class extends Controller {
  static targets = ["form"];

  connect() {}

  search(evt) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      try {
        this.formTarget.dispatchEvent(
          new Event("submit", {
            bubbles: true,
            cancelable: true,
          })
        );
      } catch (e) {
        if (this.formTarget.requestSubmit) {
          this.formTarget.requestSubmit();
        } else {
          this.formTarget.submit();
        }
      }
    }, 200);
  }
}
