import Image from "@tiptap/extension-image";

const ContentImage = Image.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      src: {
        default: null,
      },
      srcset: {
        default: null,
      },
      sizes: {
        default: null,
      },
      class: {
        default: null,
      },
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-content-image-id"),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }

          return {
            "data-content-image-id": attributes.id,
          };
        },
      },
    };
  },
});

export default ContentImage;
