const getOffset = (el) => {
  const rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
};

const jumpBodyScrollTo = (y = 0) => {
  const html = document.querySelector("html");
  html.classList.add("scroll-behavior:auto");
  window.setTimeout(() => {
    window.scrollTo(0, y);
    html.classList.remove("scroll-behavior:auto");
  });
};

const BodyScroll = {
  fix() {
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = "fixed";
    document.body.style.width = "100%";
    document.body.classList.add("scroll-lock");
  },

  release() {
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    document.body.style.width = "";
    document.body.classList.remove("scroll-lock");
    jumpBodyScrollTo(parseInt(scrollY || "0") * -1);
  },

  to(element) {
    if (!("getBoundingClientRect" in element)) {
      return;
    }
    const offset = getOffset(element);
    jumpBodyScrollTo(offset.top);
  },
};

export default BodyScroll;
