import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.cards = this.element.querySelectorAll('.story-card.switcher')
    if (!this.cards.length) {
      return;
    }

    // Set up a mutation observer
    this.observer = new ResizeObserver(this.onResize.bind(this));
    this.observer.observe(this.element);
  }

  onResize() {
    this.cards.forEach(card => this.update(card));
  }

  disconnect() {
    this.observer.disconnect();
  }

  update(card) {
    const image = card.querySelector('.image');
    if (card.offsetWidth === image.offsetWidth) {
      card.classList.remove('wide')
    } else {
      card.classList.add('wide');
    }
  }
}
