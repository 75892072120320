import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"];

  connect() {}

  targetPage() {
    this.element.dataset.turboFrame = "_top";
  }

  targetFrame() {
    this.element.removeAttribute("data-turbo-frame");
  }
}
