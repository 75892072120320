import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["trigger"];
  static values = {
    triggerParam: String,
  };

  connect() {
    if (!this.hasTriggerTarget) {
      return;
    }

    const params = new URLSearchParams(document.location.search);
    const keys = Array.from(params.keys());

    if (keys.includes(this.triggerParamValue)) {
      window.setTimeout(() => {
        this.triggerTarget.click();
      }, 1000);
    }
  }
}
