const sliderProps = {
  fill: "var(--c-green)",
  background: "var(--c-border)",
};

function initFill(input) {
  input.addEventListener("input", (event) => applyFill(event.target));
  // double this up so we can programmatically update this without firing the input event.
  input.addEventListener("range:update-slider-fill", (event) =>
    applyFill(event.target)
  );
  applyFill(input);
}

function applyFill(slider) {
  const percentage =
    (100 * (slider.value - slider.min)) / (slider.max - slider.min);
  const bg = `linear-gradient(90deg, ${sliderProps.fill} ${percentage}%, ${
    sliderProps.background
  } ${percentage + 0.1}%)`;
  slider.style.background = bg;
}

document.addEventListener("turbo:load", () => {
  document
    .querySelectorAll('input[type="range"]')
    .forEach((input) => initFill(input));
});
