import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.video = this.element.querySelector("video");
    this.loaded = false;

    if (!this.video) {
      return;
    }

    this.element.addEventListener("mouseenter", this._play.bind(this));
    this.element.addEventListener("mouseleave", this._pause.bind(this));
  }

  disconnect() {
    this.element.removeEventListener("mouseenter", this._play);
    this.element.removeEventListener("mouseleave", this._pause);
  }

  _play() {
    if (!this.loaded) {
      const preload = () => {
        this.video.removeEventListener("canplaythrough", preload);
        this.loaded = true;
        this.video.play();
      };
      this.video.load();
      this.video.removeAttribute("preload");
      this.video.addEventListener("canplaythrough", preload);
    } else {
      this.video.play();
    }
  }

  _pause() {
    this.video.pause();
  }
}
