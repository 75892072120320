// <div class="relative"
//      data-controller="dropdown"
//      data-action="click->dropdown#toggle click@window->dropdown#hide"
//      data-dropdown-target="menu">
//  <div data-action="click->dropdown#toggle click@window->dropdown#hide" role="button" data-dropdown-target="button" tabindex="0" class="inline-block select-none">
//    Open Dropdown
//  </div>
//  <div data-dropdown-target="menu"
//      data-transition-enter="transition:default"
//      data-transition-enter-start="opacity:0 scale:95"
//      data-transition-enter-end="opacity:100 scale:100"
//      data-transition-leave="transition:quick"
//      data-transition-leave-start="opacity:100 scale:100"
//      data-transition-leave-end="opacity:0 scale:95"
//    >
//    <div class="bg-white shadow rounded border overflow-hidden">
//      Content
//    </div>
//  </div>
// </div>

import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "../utils/el-transition";

export default class extends Controller {
  static targets = ["menu", "button"];
  static values = { open: Boolean };

  connect() {
    if (this.hasButtonTarget) {
      this.buttonTarget.addEventListener("keydown", this._onMenuButtonKeydown);
    }

    this.element.setAttribute("aria-haspopup", "true");
  }

  disconnect() {
    if (this.hasButtonTarget) {
      this.buttonTarget.removeEventListener(
        "keydown",
        this._onMenuButtonKeydown
      );
    }
  }

  toggle() {
    this.openValue = !this.openValue;
  }

  openValueChanged() {
    if (this.openValue) {
      this._show();
    } else {
      this._hide();
    }
  }

  async _show(cb) {
    await enter(this.menuTarget);
    if (this.hasButtonTarget) {
      this.buttonTarget.setAttribute("aria-expanded", "true");
    } else {
      this.element.setAttribute("aria-expanded", "true");
    }
    if (typeof cb == "function") {
      cb();
    }
  }

  async _hide(cb) {
    await leave(this.menuTarget);
    if (this.hasButtonTarget) {
      this.buttonTarget.setAttribute("aria-expanded", "false");
    } else {
      this.element.setAttribute("aria-expanded", "false");
    }
    if (typeof cb == "function") {
      cb();
    }
  }

  _onMenuButtonKeydown = (event) => {
    switch (event.keyCode) {
      case 13: // enter
      case 32: // space
        event.preventDefault();
        this.toggle();
    }
  };

  show() {
    this.openValue = true;
  }

  hide(event) {
    if (this.element.contains(event.target) === false && this.openValue) {
      this.openValue = false;
    }
  }

  get activeTarget() {
    return this.data.has("activeTarget")
      ? document.querySelector(this.data.get("activeTarget"))
      : this.element;
  }
}
