import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if (!this.element.dataset.heightReaderProperty) {
      console.error(
        `Element has a height reader but you need to define data-height-reader-propery attribute`,
        this.element
      );
      return;
    }

    // Set up a mutation observer
    this.observer = new MutationObserver(this.onMutate.bind(this));
    this.observer.observe(this.element, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    // And a resize listener
    window.addEventListener("resize", this.calculateHeight.bind(this));

    this.calculateHeight();
  }

  onMutate(mutations, observer) {
    this.calculateHeight();
  }

  disconnect() {
    document.body.style.setProperty(
      this.element.dataset.heightReaderProperty,
      `0`
    );
    this.observer.disconnect();
    window.removeEventListener("resize", this.calculateHeight);
  }

  calculateHeight() {
    document.body.style.setProperty(
      this.element.dataset.heightReaderProperty,
      `${this.element.offsetHeight}px`
    );
  }
}
